import { getFinnRequestId } from '@finn/platform-modules';
import { getRequestMetadataForWebViewApp } from '@finn/ua-app';
import { IS_DIRECT_CHECKOUT_ENABLED } from '@finn/ua-constants';
import {
  config,
  CookieKeys,
  generateLocalizationHeaders,
  getClientCookie,
  isServer,
  REQUEST_ID_HEADER_NAME,
  traceabilityHeadersBrowser,
} from '@finn/ui-utils/ssr';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import axiosRetry from 'axios-retry';
import { parse } from 'query-string';

import {
  ComingSoonDealCreationBody,
  ConfirmationPayload,
  ContactUpdateBody,
  D2CJson,
  DealCreationBody,
  DealCreationWithAuth,
  DealInfo,
  DirectCheckoutDealCreationBody,
  DirectCheckoutDealCreationResult,
  DirectCheckoutEligibilityBody,
  DirectCheckoutEligibilityResult,
  GetAvailabilityBody,
  IntentCreationInfo,
  LeadCreationBody,
  LicenseInfo,
  LocationUnavailableDealCreationBody,
  PaymentInfo,
  ProcessPaymentInfo,
  UpdateDealInfo,
} from '~/types/checkout';

import { axiosErrorHandler } from './axiosUtils';

export type CheckoutRequestOptions = {
  timeout?: number;
  cookie?: string;
  withCredentials?: boolean;
  isProxy?: boolean;
};

export const callCheckoutEndpointPlain = async <T>(
  endpoint: string,
  options: AxiosRequestConfig,
  isProxy = true,
  method: Method = 'GET'
) => {
  options.method = method;
  options.url = `${config.CHECKOUT_API_URL}/${endpoint}`;
  options.headers = {
    ...options.headers,
    'X-Request-Source': 'web',
    'X-Request-ABS': 'enabled',
    [REQUEST_ID_HEADER_NAME]: getFinnRequestId(),
    ...traceabilityHeadersBrowser(),
  };

  if (isProxy && !isServer()) {
    options.url = `${window.origin}/api/checkout/${endpoint}`;
  }

  return axios.request<T>(options);
};

const callCheckoutEndpoint = async <T = any>(
  endpoint: string,
  payload:
    | {}
    | DealCreationBody
    | ContactUpdateBody
    | DealCreationWithAuth
    | ProcessPaymentInfo
    | PaymentInfo
    | DealInfo
    | ConfirmationPayload
    | LicenseInfo
    | IntentCreationInfo
    | DirectCheckoutEligibilityBody
    | DirectCheckoutDealCreationBody
    | GetAvailabilityBody
    | D2CJson,
  { timeout, cookie, withCredentials, isProxy }: CheckoutRequestOptions,
  method: Method = 'POST',
  locale: string,
  isE2ETest = false,
  traceabilityHeaders?: Record<string, string>
): Promise<T> => {
  const requestId = getFinnRequestId();
  if (!traceabilityHeaders) {
    if (isServer()) {
      traceabilityHeaders = {};
      console.warn(
        `checkout endpoint: ${endpoint} was called from server without traceability headers`
      );
    } else {
      traceabilityHeaders = traceabilityHeadersBrowser();
    }
  }

  const { appQueryParams, appHeaders } = getRequestMetadataForWebViewApp();
  const dataParams = method === 'GET' ? payload || {} : {};
  const options: AxiosRequestConfig = {
    method,
    data: payload,
    timeout,
    url: `${config.CHECKOUT_API_URL}/${endpoint}`,
    params: { ...(appQueryParams || {}), ...dataParams },
    headers: {
      ...generateLocalizationHeaders(locale),
      ...(isE2ETest ? { 'X-Test-In-Progress': 'yes' } : {}),
      'X-Stripe-Payment-Element': 'enabled',
      'X-Request-Source': 'web',
      'X-Request-ABS': 'enabled',
      [REQUEST_ID_HEADER_NAME]: requestId,
      ...appHeaders,
      ...traceabilityHeaders,
    },
  };

  if (isProxy && !isServer()) {
    const queryObj = parse(window?.location?.search);
    const utmParams = queryObj?.utm_source
      ? { utm_source: queryObj?.utm_source }
      : {};
    options.params = { ...options.params, ...utmParams };
    options.url = `${window.origin}/api/checkout/${endpoint}`;
    if (getClientCookie(CookieKeys.E2E_TEST_IN_PROGRESS) === 'yes') {
      options.headers = { ...options.headers, 'X-Test-In-Progress': 'yes' };
    }
  }

  if (withCredentials) {
    options.withCredentials = true;
  }

  if (cookie) {
    options.withCredentials = true;
    options.headers = {
      Cookie: cookie,
      ...generateLocalizationHeaders(locale),
      ...options.headers,
    };
  }

  try {
    axiosRetry(axios, {
      retries: 2,
      shouldResetTimeout: true,
      retryDelay: (retryCount: number) => {
        return retryCount * 500; // 500ms delay for first retry, 1s for the second
      },
      retryCondition: (error: AxiosError) => {
        // direct checkout eligibility gives error on expected failure. no need to retry
        if (endpoint === 'directCheckout/eligibility') {
          return false;
        }
        // these are known error codes that we don't want to retry
        if ([400, 404, 406, 410, 409].includes(error?.response?.status)) {
          return false;
        }

        return true;
      },
    });
    const { data, status } = await axios.request<T>(options);

    return { ...data, status };
  } catch (error) {
    return {
      requestId,
      ...axiosErrorHandler(error),
    } as any;
  }
};

export const CHECKOUT_TIMEOUT_THRESHOLD = 20000;

export const directCheckoutEligibility = async (
  dealInfo: DirectCheckoutEligibilityBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint<DirectCheckoutEligibilityResult>(
    'directCheckout/eligibility',
    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );

export const directCheckout = async (
  dealInfo: DirectCheckoutDealCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) => {
  return await callCheckoutEndpoint<DirectCheckoutDealCreationResult>(
    'directCheckout/create',
    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );
};

export const createLead = async (
  contactData: LeadCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
    isComingSoon?: boolean;
  }
) => {
  const isTesting =
    getClientCookie(CookieKeys.DISABLE_DIRECT_CHECKOUT_FOR_E2E) === 'yes';
  const isDirectCheckoutEnabled = isTesting
    ? false
    : IS_DIRECT_CHECKOUT_ENABLED;

  return await callCheckoutEndpoint(
    'deal',
    { ...contactData, isDirectCheckoutEnabled },
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );
};

export const updateContact = async (
  contactData: ContactUpdateBody,
  contactId: number | string,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
    isComingSoon?: boolean;
  }
) =>
  await callCheckoutEndpoint(
    `contact/${contactId}`,
    contactData,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'PATCH',
    locale
  );

export const createUnavailableDeal = async (
  dealInfo: ComingSoonDealCreationBody | LocationUnavailableDealCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'processDealCreationComingSoon',

    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );

export const processAddress = async (
  dealInfo: DealCreationBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'deal/address',
    dealInfo,
    {
      timeout,
      isProxy: true,
      withCredentials: true,
    },
    'POST',
    locale
  );

export const updateDeal = async (
  updateDealInfo: UpdateDealInfo,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'updateDeal',
    updateDealInfo,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

export const getClientSecret = async (
  intentCreationInfo: IntentCreationInfo,
  { timeout = CHECKOUT_TIMEOUT_THRESHOLD, locale }
) =>
  await callCheckoutEndpoint(
    'createSetupIntent',
    intentCreationInfo,
    { timeout, isProxy: true },
    'POST',
    locale
  );

export const processPayment = async <T>(
  paymentInfo: T,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'processPayment',
    paymentInfo,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

export const processConfirm = async (
  payload: ConfirmationPayload,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
    locale,
  }: {
    timeout?: number;
    locale: string;
  }
) =>
  await callCheckoutEndpoint(
    'confirm',
    payload,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    locale
  );

export const restoreProcess = async (
  dealInfo: DealInfo | {},
  checkoutRequestOptions: CheckoutRequestOptions,
  locale: string,
  isE2ETest = false,
  traceabilityHeaders?: Record<string, string>
) => {
  const data = await callCheckoutEndpoint(
    'restore',
    dealInfo,
    checkoutRequestOptions,
    'POST',
    locale,
    isE2ETest,
    traceabilityHeaders
  );

  return data;
};

export const getAvailability = async (payload: GetAvailabilityBody) => {
  const data = await callCheckoutEndpoint(
    'availability',
    payload,
    {
      isProxy: true,
    },
    'GET',
    'de-DE' // refactor in progress to remove this so for now passing de-DE
  );

  return data;
};
